<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "PrivacyPolicy",
  metaInfo: {
    title: "Privacy policy",
    titleTemplate: "%s | Tentifly - Smart sales | booking assistant"
  },
  extends: View,
  mixins: [LoadSections(["privacy-policy"])],
  props: {
    id: {
      type: String,
      default: "about"
    }
  }
};
</script>
